<template>
  <div
    v-if="showAlert"
    class="fixed inset-x-0 bottom-0 p-4 mb-8 text-center z-50"
  >
    <div
      class="bg-white rounded-lg shadow-lg p-4 flex items-center justify-between mx-auto h-20"
      style="width: 400px"
    >
      <div class="flex items-center flex-shrink-0">
        <div
          :style="circleStyle"
          class="rounded-full flex items-center justify-center"
        >
          <Icon :icon="alert.icon" :color="iconColor" width="1.5em" />
        </div>
      </div>
      <div class="flex flex-grow text-left justify-center ml-3 items-center">
        <div>
          <div :style="titleStyle" class="font-semibold text-center">
            {{ titleText }}
          </div>
          <p :class="textClass" class="text-center">
            {{ messageText }}
          </p>
        </div>
      </div>
      <button
        @click="closeSnackbar"
        class="bg-transparent border-none text-gray-500 rounded-full p-2 hover:bg-gray-200 transition"
      >
        <Icon icon="ic:round-close" width="1.5em" />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useAlertStore } from "@/store/alert";
import { ref, computed } from "vue";
import { Icon } from "@iconify/vue";

const alertStore = useAlertStore();
const { alert, showAlert } = storeToRefs(alertStore);
const { setShowAlert } = alertStore;

const closeSnackbar = () => {
  setShowAlert(false);
};

const numberOfClients = ref(0); // Você pode definir este valor baseado em seus dados

const circleStyle = computed(() => {
  let backgroundColor = "";
  if (alert.value.type === "success") {
    backgroundColor = "#EEF4E1";
  } else if (alert.value.type === "error") {
    backgroundColor = "#F8EFEF";
  } else {
    backgroundColor = "#DEEAEF";
  }
  return `background-color: ${backgroundColor}; width: 3rem; height: 3rem;`;
});

const iconColor = computed(() => {
  if (alert.value.type === "success") {
    return "#739B1A";
  } else if (alert.value.type === "error") {
    return "#FF0000";
  } else {
    return "#124559";
  }
});

const titleStyle = computed(() => {
  if (alert.value.type === "success") {
    return "color: #739B1A;";
  } else if (alert.value.type === "error") {
    return "color: #FF0000;";
  } else {
    return "color: #124559;";
  }
});

const titleText = computed(() => {
  if (alert.value.type === "clients") {
    return `${numberOfClients.value} clientes`;
  }
  return alert.value.message.title;
});

const messageText = computed(() => {
  if (alert.value.type === "clients") {
    return `Você possui ${numberOfClients.value} clientes ativos.`;
  }
  return alert.value.message.text;
});

const textClass = computed(() => {
  return alert.value.type === "clients" ? "text-[#124559]" : "text-[#8D8D8D]";
});

watch(showAlert, (newVal) => {
  if (newVal) {
    setTimeout(() => {
      setShowAlert(false);
    }, 5000);
  }
});
</script>
