import { default as _91id_93CWX6nevVyxMeta } from "/vercel/path0/pages/assinantes/edit/[id].vue?macro=true";
import { default as index6eXhhCGYoVMeta } from "/vercel/path0/pages/assinantes/index.vue?macro=true";
import { default as new6jHT5sXmZLMeta } from "/vercel/path0/pages/assinantes/new.vue?macro=true";
import { default as _91id_93yTYfTvBM4hMeta } from "/vercel/path0/pages/assinantes/view/[id].vue?macro=true";
import { default as indexkppHktB81xMeta } from "/vercel/path0/pages/auth/index.vue?macro=true";
import { default as clientesZxYhNGEghKMeta } from "/vercel/path0/pages/clientes.vue?macro=true";
import { default as index1E96u3aR9mMeta } from "/vercel/path0/pages/conteudos/index.vue?macro=true";
import { default as newrgN9yoz88yMeta } from "/vercel/path0/pages/conteudos/new.vue?macro=true";
import { default as _91id_93KdEEFeGtleMeta } from "/vercel/path0/pages/conteudos/view/[id].vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as indexdksLA0C5KzMeta } from "/vercel/path0/pages/meu-perfil/[id]/index.vue?macro=true";
import { default as editar9shgD2g1dXMeta } from "/vercel/path0/pages/playlists/[id]/editar.vue?macro=true";
import { default as indexyngUDHnYIEMeta } from "/vercel/path0/pages/playlists/index.vue?macro=true";
import { default as newGpan6SJ5LfMeta } from "/vercel/path0/pages/playlists/new.vue?macro=true";
import { default as indexl2IGXNOj5jMeta } from "/vercel/path0/pages/relatorios/index.vue?macro=true";
import { default as gradeibWmC2uCIxMeta } from "/vercel/path0/pages/terminais/[id]/grade.vue?macro=true";
import { default as _91id_93rbdfgaHFRgMeta } from "/vercel/path0/pages/terminais/edit/[id].vue?macro=true";
import { default as indexsFhBt96IIIMeta } from "/vercel/path0/pages/terminais/index.vue?macro=true";
import { default as new1QZE0jhztoMeta } from "/vercel/path0/pages/terminais/new.vue?macro=true";
import { default as _91id_93kpuobAg526Meta } from "/vercel/path0/pages/terminais/view/[id].vue?macro=true";
import { default as _91id_93dt38fY7js0Meta } from "/vercel/path0/pages/usuarios/edit/[id].vue?macro=true";
import { default as indexwPrl0ElyT8Meta } from "/vercel/path0/pages/usuarios/index.vue?macro=true";
import { default as newPmyirnAS1NMeta } from "/vercel/path0/pages/usuarios/new.vue?macro=true";
import { default as _91id_93ksmMZgBdacMeta } from "/vercel/path0/pages/usuarios/view/[id].vue?macro=true";
export default [
  {
    name: _91id_93CWX6nevVyxMeta?.name ?? "assinantes-edit-id",
    path: _91id_93CWX6nevVyxMeta?.path ?? "/assinantes/edit/:id()",
    meta: _91id_93CWX6nevVyxMeta || {},
    alias: _91id_93CWX6nevVyxMeta?.alias || [],
    redirect: _91id_93CWX6nevVyxMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/assinantes/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: index6eXhhCGYoVMeta?.name ?? "assinantes",
    path: index6eXhhCGYoVMeta?.path ?? "/assinantes",
    meta: index6eXhhCGYoVMeta || {},
    alias: index6eXhhCGYoVMeta?.alias || [],
    redirect: index6eXhhCGYoVMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/assinantes/index.vue").then(m => m.default || m)
  },
  {
    name: new6jHT5sXmZLMeta?.name ?? "assinantes-new",
    path: new6jHT5sXmZLMeta?.path ?? "/assinantes/new",
    meta: new6jHT5sXmZLMeta || {},
    alias: new6jHT5sXmZLMeta?.alias || [],
    redirect: new6jHT5sXmZLMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/assinantes/new.vue").then(m => m.default || m)
  },
  {
    name: _91id_93yTYfTvBM4hMeta?.name ?? "assinantes-view-id",
    path: _91id_93yTYfTvBM4hMeta?.path ?? "/assinantes/view/:id()",
    meta: _91id_93yTYfTvBM4hMeta || {},
    alias: _91id_93yTYfTvBM4hMeta?.alias || [],
    redirect: _91id_93yTYfTvBM4hMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/assinantes/view/[id].vue").then(m => m.default || m)
  },
  {
    name: indexkppHktB81xMeta?.name ?? "auth",
    path: indexkppHktB81xMeta?.path ?? "/auth",
    meta: indexkppHktB81xMeta || {},
    alias: indexkppHktB81xMeta?.alias || [],
    redirect: indexkppHktB81xMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: clientesZxYhNGEghKMeta?.name ?? "clientes",
    path: clientesZxYhNGEghKMeta?.path ?? "/clientes",
    meta: clientesZxYhNGEghKMeta || {},
    alias: clientesZxYhNGEghKMeta?.alias || [],
    redirect: clientesZxYhNGEghKMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/clientes.vue").then(m => m.default || m)
  },
  {
    name: index1E96u3aR9mMeta?.name ?? "conteudos",
    path: index1E96u3aR9mMeta?.path ?? "/conteudos",
    meta: index1E96u3aR9mMeta || {},
    alias: index1E96u3aR9mMeta?.alias || [],
    redirect: index1E96u3aR9mMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/conteudos/index.vue").then(m => m.default || m)
  },
  {
    name: newrgN9yoz88yMeta?.name ?? "conteudos-new",
    path: newrgN9yoz88yMeta?.path ?? "/conteudos/new",
    meta: newrgN9yoz88yMeta || {},
    alias: newrgN9yoz88yMeta?.alias || [],
    redirect: newrgN9yoz88yMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/conteudos/new.vue").then(m => m.default || m)
  },
  {
    name: _91id_93KdEEFeGtleMeta?.name ?? "conteudos-view-id",
    path: _91id_93KdEEFeGtleMeta?.path ?? "/conteudos/view/:id()",
    meta: _91id_93KdEEFeGtleMeta || {},
    alias: _91id_93KdEEFeGtleMeta?.alias || [],
    redirect: _91id_93KdEEFeGtleMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/conteudos/view/[id].vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexdksLA0C5KzMeta?.name ?? "meu-perfil-id",
    path: indexdksLA0C5KzMeta?.path ?? "/meu-perfil/:id()",
    meta: indexdksLA0C5KzMeta || {},
    alias: indexdksLA0C5KzMeta?.alias || [],
    redirect: indexdksLA0C5KzMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/meu-perfil/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: editar9shgD2g1dXMeta?.name ?? "playlists-id-editar",
    path: editar9shgD2g1dXMeta?.path ?? "/playlists/:id()/editar",
    meta: editar9shgD2g1dXMeta || {},
    alias: editar9shgD2g1dXMeta?.alias || [],
    redirect: editar9shgD2g1dXMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/playlists/[id]/editar.vue").then(m => m.default || m)
  },
  {
    name: indexyngUDHnYIEMeta?.name ?? "playlists",
    path: indexyngUDHnYIEMeta?.path ?? "/playlists",
    meta: indexyngUDHnYIEMeta || {},
    alias: indexyngUDHnYIEMeta?.alias || [],
    redirect: indexyngUDHnYIEMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/playlists/index.vue").then(m => m.default || m)
  },
  {
    name: newGpan6SJ5LfMeta?.name ?? "playlists-new",
    path: newGpan6SJ5LfMeta?.path ?? "/playlists/new",
    meta: newGpan6SJ5LfMeta || {},
    alias: newGpan6SJ5LfMeta?.alias || [],
    redirect: newGpan6SJ5LfMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/playlists/new.vue").then(m => m.default || m)
  },
  {
    name: indexl2IGXNOj5jMeta?.name ?? "relatorios",
    path: indexl2IGXNOj5jMeta?.path ?? "/relatorios",
    meta: indexl2IGXNOj5jMeta || {},
    alias: indexl2IGXNOj5jMeta?.alias || [],
    redirect: indexl2IGXNOj5jMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/relatorios/index.vue").then(m => m.default || m)
  },
  {
    name: gradeibWmC2uCIxMeta?.name ?? "terminais-id-grade",
    path: gradeibWmC2uCIxMeta?.path ?? "/terminais/:id()/grade",
    meta: gradeibWmC2uCIxMeta || {},
    alias: gradeibWmC2uCIxMeta?.alias || [],
    redirect: gradeibWmC2uCIxMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/terminais/[id]/grade.vue").then(m => m.default || m)
  },
  {
    name: _91id_93rbdfgaHFRgMeta?.name ?? "terminais-edit-id",
    path: _91id_93rbdfgaHFRgMeta?.path ?? "/terminais/edit/:id()",
    meta: _91id_93rbdfgaHFRgMeta || {},
    alias: _91id_93rbdfgaHFRgMeta?.alias || [],
    redirect: _91id_93rbdfgaHFRgMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/terminais/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: indexsFhBt96IIIMeta?.name ?? "terminais",
    path: indexsFhBt96IIIMeta?.path ?? "/terminais",
    meta: indexsFhBt96IIIMeta || {},
    alias: indexsFhBt96IIIMeta?.alias || [],
    redirect: indexsFhBt96IIIMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/terminais/index.vue").then(m => m.default || m)
  },
  {
    name: new1QZE0jhztoMeta?.name ?? "terminais-new",
    path: new1QZE0jhztoMeta?.path ?? "/terminais/new",
    meta: new1QZE0jhztoMeta || {},
    alias: new1QZE0jhztoMeta?.alias || [],
    redirect: new1QZE0jhztoMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/terminais/new.vue").then(m => m.default || m)
  },
  {
    name: _91id_93kpuobAg526Meta?.name ?? "terminais-view-id",
    path: _91id_93kpuobAg526Meta?.path ?? "/terminais/view/:id()",
    meta: _91id_93kpuobAg526Meta || {},
    alias: _91id_93kpuobAg526Meta?.alias || [],
    redirect: _91id_93kpuobAg526Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/terminais/view/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93dt38fY7js0Meta?.name ?? "usuarios-edit-id",
    path: _91id_93dt38fY7js0Meta?.path ?? "/usuarios/edit/:id()",
    meta: _91id_93dt38fY7js0Meta || {},
    alias: _91id_93dt38fY7js0Meta?.alias || [],
    redirect: _91id_93dt38fY7js0Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/usuarios/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: indexwPrl0ElyT8Meta?.name ?? "usuarios",
    path: indexwPrl0ElyT8Meta?.path ?? "/usuarios",
    meta: indexwPrl0ElyT8Meta || {},
    alias: indexwPrl0ElyT8Meta?.alias || [],
    redirect: indexwPrl0ElyT8Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/usuarios/index.vue").then(m => m.default || m)
  },
  {
    name: newPmyirnAS1NMeta?.name ?? "usuarios-new",
    path: newPmyirnAS1NMeta?.path ?? "/usuarios/new",
    meta: newPmyirnAS1NMeta || {},
    alias: newPmyirnAS1NMeta?.alias || [],
    redirect: newPmyirnAS1NMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/usuarios/new.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ksmMZgBdacMeta?.name ?? "usuarios-view-id",
    path: _91id_93ksmMZgBdacMeta?.path ?? "/usuarios/view/:id()",
    meta: _91id_93ksmMZgBdacMeta || {},
    alias: _91id_93ksmMZgBdacMeta?.alias || [],
    redirect: _91id_93ksmMZgBdacMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/usuarios/view/[id].vue").then(m => m.default || m)
  }
]