import { defineStore } from "pinia";

interface IAlert {
  type: string;
  message: {
    title: string;
    text: string;
  };
  icon: string;
}

export const useAlertStore = defineStore({
  id: "alert-store",
  state: () => {
    return {
      alertInfo: {} as IAlert,
      showAlertInfo: false,
    };
  },
  actions: {
    setAlert(value: IAlert) {
      this.alertInfo = value;
      this.showAlertInfo = true;
    },
    setShowAlert(value: boolean) {
      this.showAlertInfo = value;
    },
  },
  getters: {
    alert: (state) => state.alertInfo,
    showAlert: (state) => state.showAlertInfo,
  },
});
