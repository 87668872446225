import { storeToRefs } from "pinia";
import { useAuthUserStore } from "@/store/users";
import apiResponse from "@/interfaces/apiResponse";

export default defineNuxtRouteMiddleware((to) => {
  const useAuthStore = useAuthUserStore();
  const { setAuthenticatedUser, isValidToken } = useAuthStore;
  const { authenticatedUser } = storeToRefs(useAuthStore);

  const userToken = useCookie("token");

  if (
    !userToken.value &&
    to.path !== "/auth" &&
    to.path !== "/recuperar-senha"
  ) {
    return navigateTo("/auth");
  }
  const validateToken = async () => {
    const { data, error } = await isValidToken();
    if (error.value) {
      setAuthenticatedUser({});
      userToken.value = null;
      return navigateTo("/auth");
    }
    const isValid = data.value;
    return isValid;
  };
  if (!authenticatedUser.value.auth && to.path !== "/auth" && userToken) {
    validateToken()
      .then((isValid: apiResponse) => {
        if (isValid.statusCode === 200) {
          setAuthenticatedUser(isValid.data);
        }

        if (
          authenticatedUser.value?.type === "technician" &&
          to.path !== "/auth"
        ) {
          return navigateTo("/");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
});
