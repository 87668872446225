import { defineStore } from "pinia";

export const useAuthUserStore = defineStore({
  id: "auth-user-store",
  state: () => {
    return {
      authUserInfo: {},
      usersState: [],
    };
  },
  actions: {
    setAuthenticatedUser(value: object) {
      this.authUserInfo = value;
    },

    async isValidToken() {
      const response = await useFetch(`/api/auth/validate`);
      return response;
    },

    async fetch(query: string) {
      try {
        const response = await fetch(`/api/users${query}`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const { data } = await response.json();

        this.usersState = data;
      } catch (error) {
        console.error("An error occurred while fetching data: ", error);
      }
    },
  },
  getters: {
    authenticatedUser: (state) => state.authUserInfo,
    users: (state) => state.usersState,
  },
});
